const calculateOpexAmount = (monthly, oneTime, opexArray, calculateYearly, includeOneTime, variableOpexRate) => {
 let totalDeposit = (monthly * 12) + oneTime
 let oneTimePayment = (includeOneTime ? oneTime : 0)
 if (opexArray.length === 1) {
    return (opexArray[0].opexValue) * totalDeposit
 } else {
    let calcBasis = ((calculateYearly ? monthly * 12 : monthly) + (calculateYearly ? oneTimePayment : oneTimePayment / 12))
    if (!variableOpexRate) {
        for (let i = 0; opexArray.length > i; i++) {
            if (calcBasis <= opexArray[i].opexTop) {
                return opexArray[i].opexValue * totalDeposit
            }
        }
    } else {
        let variableOpexAmount = 0
        let sumOpexTop = 0
        for (let i = 0; opexArray.length > i; i++) {
            if (calcBasis > 0) {
                if (calcBasis > opexArray[i].opexTop) {
                    variableOpexAmount += (opexArray[i].opexTop * opexArray[i].opexValue)
                    calcBasis -= opexArray[i].opexTop
                    sumOpexTop += (calculateYearly ? opexArray[i].opexTop : opexArray[i].opexTop * 12)
                } else {
                    variableOpexAmount += ((totalDeposit - sumOpexTop) * opexArray[i].opexValue)
                    break
                }
            } else {
                break
            }
        }
        return variableOpexAmount
    }
 }
}

export default calculateOpexAmount