import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import parse from 'html-react-parser'
import Slider from "react-slick"
import SliderComponent from "./SliderComponent"
import getOpexArray from "../Math/getOpexArray"
import calculateTaxCredit from "../Math/calculateTaxCredit"
import calculateSpendableAmount from "../Math/calculateSpendableAmount"
import calculateFirstYearSavings from "../Math/calculateFirstYearSavings"
import calculateYearlyFees from "../Math/calculateYearlyFees"
import cart from "../assets/img/cart.png"
import attachment from "../assets/img/attachment.png"
import useResponsiveWidth from "../Auxilliary/useResponsiveWidth"
import createMobileSlider from "../Auxilliary/createMobileSlider"

const OfferCard = (props) => {
    let settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        dots: props.showDots,
        className: 'medsavings-app__offerCard__body__carousel',
        responsive: [
            {
                breakpoint: 890,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.1                    
                }
            },
            {
                breakpoint: 385,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }
    let opexArray = getOpexArray(props.monthlyPayment, props.productOpexRate, props.calculateYearly, props.includeOneTime, props.oneTimePayment)
    let fixYearlyFees = calculateYearlyFees(props.productYearlyFee.yearlyValues, props.entryFeeLabel, props.cardFeeLabel) 
    let taxCredit = calculateTaxCredit(props.monthlyPayment, props.oneTimePayment, props.taxKey, props.taxMax)
    let spendableAmount = calculateSpendableAmount(props.monthlyPayment, props.oneTimePayment, props.totalOpexAmount, taxCredit, fixYearlyFees.totalFees, props.allowTaxCredit)
    let totalSavings = calculateFirstYearSavings(taxCredit, fixYearlyFees.totalFees, props.totalOpexAmount)
    let productLogoCss = props.productLogoCss.split(';').map(cur => cur.split(':')).reduce((acc, val) => {
        let [key, value] = val;
        key = key.replace(/-./g, css => css.toUpperCase()[1])
        acc[key] = value;
        return acc;
    }, {});
    let additionalSlides = props.productAttributes.map((productElement, index) => {
        return (
            <SliderComponent 
                key = {productElement.attributeLabel + index}
                value = {productElement.attributeValue}
                label = {productElement.attributeLabel}
                highlight = {false}
                isTick = {productElement.attributeIsTick}
            />
        )
    }) 
    let offerCardClass = props.productIsNew ? "medsavings-app__offerCard medsavings-app__offerCard--new" : "medsavings-app__offerCard"
    if (props.sponsoredProduct) {offerCardClass += " medsavings-app__offerCard--sponsored"}

    let siderComponentArray = [
        <SliderComponent 
            value={new Intl.NumberFormat("fr-FR").format(props.totalDeposit) + " Ft"}
            label={props.totalDepositLabel}
            highlight={opexArray.opexHighlight}
            key="totalDepositValue"
        />,
        (props.showTotalSpendAmount && <SliderComponent 
            value={new Intl.NumberFormat("fr-FR").format(spendableAmount) + " Ft"}
            label={props.spendAmountLabel}
            highlight={opexArray.opexHighlight}
            key="totalSpendAmountValue"
        />),
        <SliderComponent 
            value={new Intl.NumberFormat("fr-FR").format(totalSavings) + " Ft"}
            label={props.firstYearSavingsLabel}
            highlight={false}
            key="totalSavingsValue"
        />,
        <SliderComponent 
            value={Math.round((props.realOpexRate + Number.EPSILON) * 100) / 100 + "%"}
            label={props.opexLabel}
            highlight={opexArray.opexHighlight}
            key="realOpexValue"
        />,
        (props.showEntryFee && <SliderComponent 
            value={new Intl.NumberFormat("fr-FR").format(fixYearlyFees.entryFee.value) + " Ft"}
            label={fixYearlyFees.entryFee.label}
            highlight={fixYearlyFees.entryFee.highlight}
            key="entryFeeValue"
        />),
        (props.showCardFee && <SliderComponent 
            value={new Intl.NumberFormat("fr-FR").format(fixYearlyFees.cardFee.value) + " Ft"}
            label={fixYearlyFees.cardFee.label}
            highlight={fixYearlyFees.cardFee.highlight}
            key="cardFeeValue"
        />),
        ...additionalSlides  
    ]
    let filteredSliderComponentArray = siderComponentArray.filter((el) => {return el !== false}) 

    siderComponentArray = (useResponsiveWidth() < 605 ? createMobileSlider(filteredSliderComponentArray, 3) : filteredSliderComponentArray)

    return (
        <div className={offerCardClass} >
            <div className="medsavings-app__offerCard__header">
                <div className="medsavings-app__offerCard__header__element">
                    <div className="medsavings-app__offerCard__header__element__logo">
                        <img src={props.productLogo} style={productLogoCss} alt="" />
                    </div>
                    <div className="medsavings-app__offerCard__header__element__name">{props.productName}</div>
                </div>
                {props.showRating && 
                    <div className="medsavings-app__offerCard__header__element medsavings-app__offerCard__header__element--rating">
                        <div className="medsavings-app__offerCard__header__element__icon">
                            <img src={attachment} alt="" />
                        </div>
                        <div className="medsavings-app__offerCard__header__element__label">{props.reviewerLabel}</div>
                        <div className="medsavings-app__offerCard__header__element__rating">{props.productRating}</div>
                    </div>
                }
            </div>
            {props.productInfo && <div className="medsavings-app__offerCard__callout">{parse(props.productInfo)}</div>}
            <div className="medsavings-app__offerCard__body">
                <div className="medsavings-app__offerCard__body__carousel">                
                    <Slider {...settings}>
                        {siderComponentArray}
                    </Slider>
                </div>
                <a href={props.productLink} className="medsavings-app__offerCard__body__button">
                    <div className="medsavings-app__offerCard__body__button__wrapper">
                    <img className="medsavings-app__offerCard__body__button__wrapper__icon" src={cart} alt="" />
                    <div className="medsavings-app__offerCard__body__button__wrapper__button">{props.ctaLabel}</div>
                    </div>
                </a>
            </div>
        </div>
    )
}
export default OfferCard