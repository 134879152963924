import React from "react"
import OfferCard from "./OfferCard"
import NoProductsAvailable from "./NoProductsAvailable"
import parse from 'html-react-parser'
import calculateOpexAmount from "../Math/calculateOpexAmount"

const OfferCardWrapper = (props) => {
    let products = [null]

    if (props.dataSheet) {
        products = props.dataSheet.products.map((product) => {
            if (product.productMinMonthly <= props.monthlyPayment && product.productMinOnetime <= props.oneTimePayment) {
                let totalDeposit = props.oneTimePayment + props.monthlyPayment * 12
                let totalOpexAmount = calculateOpexAmount(props.monthlyPayment, props.oneTimePayment, product.productOpexRate, product.calculateYearly, product.includeOneTime, product.variableOpexRate)
                return (
                    <OfferCard
                        sponsoredProduct = {product.sponsoredProduct} 
                        fixedPosition = {product.fixedPosition}
                        realOpexRate = {((totalOpexAmount / totalDeposit) * 100)}
                        totalDeposit = {totalDeposit}
                        totalOpexAmount = {totalOpexAmount}
                        key = {product.productId}
                        taxKey = {props.dataSheet.taxKey}                        
                        monthlyPayment = {props.monthlyPayment}
                        oneTimePayment = {props.oneTimePayment}
                        productName = {product.productName}
                        productLogo = {product.productLogo}
                        productLogoCss = {product.productLogoCss}
                        productLink = {product.productLink}
                        productRating = {product.productRating}
                        productIsNew = {product.productIsNew}
                        productAttributes = {product.productAttributes}
                        productOpexRate = {product.productOpexRate}
                        productInfo = {product.productInfo}
                        productYearlyFee = {product.productYearlyFee}
                        showRating = {product.showRating}
                        taxMax = {props.dataSheet.taxMax}
                        entryFeeLabel = {props.dataSheet.entryFeeLabel}
                        cardFeeLabel = {props.dataSheet.cardFeeLabel}
                        ctaLabel = {props.dataSheet.ctaLabel}
                        totalDepositLabel = {props.dataSheet.totalDepositLabel}
                        spendAmountLabel = {props.dataSheet.spendAmountLabel}
                        firstYearSavingsLabel = {props.dataSheet.firstYearSavingsLabel}
                        opexLabel = {props.dataSheet.opexLabel}
                        reviewerLabel = {props.dataSheet.reviewerLabel}
                        showEntryFee = {props.dataSheet.showEntryFee}
                        showCardFee = {props.dataSheet.showCardFee}
                        showTotalSpendAmount = {props.dataSheet.showTotalSpendAmount}
                        allowTaxCredit = {props.dataSheet.allowTaxCredit}
                        showDots = {props.dataSheet.showDots} 
                        calculateYearly = {product.calculateYearly}
                        includeOneTime = {product.includeOneTime}
                        variableOpexRate = {product.variableOpexRate}                       
                    />
                )
            } else {
                return null
            }
        })
    }
    let filteredProducts = products.filter((el) => {return el!= null})
    let normalSortingProducts = filteredProducts.filter((el) => {return el.props.fixedPosition === false})
    let fixedPositionProducts = filteredProducts.filter((el) => {return el.props.fixedPosition !== false})
    if (props.dataSheet && props.dataSheet.allowSorting) {
        normalSortingProducts.sort((a, b) => a.props.realOpexRate - b.props.realOpexRate)
        fixedPositionProducts.sort((a, b) => a.props.fixedPosition - b.props.fixedPosition)
    }
    let finalProductList = [...fixedPositionProducts, ...normalSortingProducts]

    return (
        <div className="medsavings-app__offerCard__wrapper">
            {props.dataSheet ? 
                (!products.some(el => el !== null) ?
                    <NoProductsAvailable 
                        label={props.dataSheet.noProductsLabel}
                    /> :
                    finalProductList
                ) :
                <NoProductsAvailable label="Az adatbázis jelenleg nem elérhető. Kérjük próbálja meg később!" />
            }
            {props.dataSheet && finalProductList.some(el => el !== null) && <div><i>{parse(props.dataSheet.disclaimerText)}</i></div>}            
        </div>
    )
}
export default OfferCardWrapper