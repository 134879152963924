const getOpexArray = (monthly, opexArray, calculateYearly, includeOneTime, oneTime) => {
    let currentOpexArray = null
    let oneTimePayment = 0
    if (includeOneTime) {
        oneTimePayment = oneTime
    }
    if (!calculateYearly) {
        for (let i = 0; i < opexArray.length; i++) {
            if ((monthly + (oneTimePayment/12)) <= (opexArray[i].opexTop)) {                     
                currentOpexArray = opexArray[i]
                break
            }
        }
    } else {
        for (let i = 0; i < opexArray.length; i++) {
            if (((monthly * 12) + oneTimePayment) <= opexArray[i].opexTop) {
                currentOpexArray = opexArray[i]
                break
            }
        }
    }
    if (currentOpexArray === null) {
        currentOpexArray = opexArray[opexArray.length - 1]
    }
    return currentOpexArray
}

export default getOpexArray