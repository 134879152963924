const createMobileSlider = (arrayOfObjects, slidePerWrapper) => {
    let initialWrapper = []
    let arr = []
    for (let i = 0; arrayOfObjects.length > i; i++) {
        if ((i + 1) % slidePerWrapper !== 0) {
            arr.push(arrayOfObjects[i])
        }
        if ((i + 1) % slidePerWrapper === 0) {
            arr.push(arrayOfObjects[i])
            let wrapperElement = <div className="medsavings-app__offerCard__body__carousel__mobile" key={"mobileWrapper" + i}>{[...arr]}</div>
            initialWrapper.push(wrapperElement)
            arr = []
        }
        if (arrayOfObjects.length - 1 === i) {
            let wrapperElement = <div className="medsavings-app__offerCard__body__carousel__mobile" key={"mobileWrapper" + i}>{[...arr]}</div>
            initialWrapper.push(wrapperElement)
        }        
    }
    return initialWrapper
}
export default createMobileSlider